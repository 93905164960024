import React, { useEffect, useState, useRef, useContext } from 'react';
import Link from 'next/link'
import { useRouter } from 'next/router'
import * as PropTypes from "prop-types";

import { Container, Nav, Navbar, NavbarBrand, NavItem } from "reactstrap";
import AppsIcon from '../../assets/icons/app.svg'
import EventOutsideNotifier from "../../higher-order-components/EventOutsideNotifier";
import axios from "axios";
import { AuthConsumer } from "../../contexts/AuthContext";
import { handleNetworkError } from "../../helpers/utils";
import { StoreContext } from "../../lib/reduxoid";
import { OaLogo } from "../../components/OaLogo";
let classes = require("classnames");
const Header = (props) => {
  let { staleProp, brand = true, menu, context = {} } = props;
  const router = useRouter();
  let [ state, setState ] = useState({
    lang : 'En',
    links : [
      // { name : 'Company', path : '#' },
      // { name : 'Reviews', path : '#' },
      // { name : 'Blog', path : '#' },
    ]
  });

  const [ storeData, dispatch ] = useContext(StoreContext);
  let { appearance } = storeData;

  let darkMode = appearance.theme === 'dark';


  return (
    <Navbar className={classes("Header", {})}>
      <Container className={classes('justify-content-center', {'justify-content-md-between' : menu})}>
        {
          brand && (
          <NavbarBrand href="/login">
            <React.Fragment>
              <OaLogo />
            </React.Fragment>
          </NavbarBrand>
          )
        }

        {
          menu && <Nav className="text-small">
            {
              state.links.map((link, i) => {
                return <li key={i} className="nav-item"><Link href={link.path}><a
                  className="nav-link">{link.name}</a></Link></li>
              })
            }
          </Nav>
        }
      </Container>
    </Navbar>
  );
};

Header.propTypes = {
  menu : PropTypes.bool,
  user : PropTypes.shape()
};

const withContext = () => (
  <AuthConsumer>
    {state => <Header context={state}/>}
  </AuthConsumer>
);

export default (withContext);
