import { useTheme } from "../hooks/useTheme";
import React from "react";
import * as PropTypes from "prop-types";
import { APP } from "../config/app_config";

export function OaLogo ({showText = false, width=null, ...props}) {
  let { theme } = useTheme()
  return <>
    <img src={`/img/logos/oa-accounts/oa-accounts${theme === "dark" ? "-dark" : ""}.svg`}
         width={width}
         layout="fill"
         className="mr-3 "
         alt={`${APP.appName} Logo`}/>
    { showText && <span className="text-oa-gold text-small text-uppercase">ACCOUNTS</span>}
  </>;
}

OaLogo.propTypes = { showText : PropTypes.bool };
