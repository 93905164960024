import useStore from "./useStore";

export function useTheme () {
  const [ store, dispatch ] = useStore();
  let { appearance } = store;

  return {
    theme : appearance.theme,
    setTheme : (theme) => {
      dispatch({ type : 'appearance/UPDATE_THEME', payload : theme });
    }
  }
}

