import {createContext, useContext, useReducer} from 'react';
import { UserContext } from "./UserContext";

//Auth Context
export const AuthContext = createContext({
  idToken : null,
  accessToken : null,
  refreshToken : null,
  token: null,
  user: null,
  setToken: (data) => { },
  setAccessToken: (data) => { },
  setIdToken: (data) => { },
  setRefreshToken: (data) => { },
  setUser: (data) => { },
})

//Use Auth Context
export function useAuthContext() {
  return useContext(AuthContext);
}

export const AuthProvider = AuthContext.Provider;
export const AuthConsumer = AuthContext.Consumer;
