import * as classes from 'classnames'
import { Row, Container } from "reactstrap";
import GlobeIcon from "../../assets/icons/globe.svg";
import { ChevronDownIcon } from "@modulz/radix-icons";
import React, { useState } from "react";
import Link from "next/link";

const Footer = (props) => {
  let { fixedBottom = false } = props;

  let [lang, setLang] = useState('En')

  return (
    <footer className={classes("Footer py-4", {
      "position-relative" : fixedBottom === false,
      "position-absolute fixed-bottom" : fixedBottom === true
    })}>
      <Container>
        <Row className="justify-content-center m-0">
          <small className="mx-2 text-small">
            <Link href={"/terms"}>
              <a>Terms & Conditions</a>
            </Link>
          </small>
          <small className="mx-2 text-small"><Link href={"/privacy-policy"}><a>Privacy policy</a></Link></small>
          {/*<small className="mx-2 text-small">Press</small>*/}
          {/*<small className="mx-2 text-small">Jobs</small>*/}
          {/*<a href="#" className="">
          <small className="mx-2 text-small">
            <GlobeIcon height={15} width={15}/> {lang}<ChevronDownIcon/>
          </small>
        </a>*/}
        </Row>
      </Container>
    </footer>
  );
};

Footer.propTypes = {};

export default Footer;
