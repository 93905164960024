import { Component, useEffect, useState, useRef, createContext, useContext } from 'react';

export const UserContext = createContext({
  user : null,
  token : null,
  updateUser : () => {
  },
  updateToken : () => {
  },
})


export class UserProvider extends Component {
  state = {
    user : null,
    token : null,
    updateUser : this.updateUser,
    updateToken : this.updateToken,
  }

  updateUser (user) {
    this.setState({user})
  }

  updateToken (token) {
    this.setState({token})
  }

  render () {
    return (
      // This provider will wrap the rest of the tree and we pass in the user in the
      // state and the updateUser function as well.
      <UserContext.Provider value={this.state}>
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

export const UserConsumer = UserContext.Consumer;
